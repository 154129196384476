import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import { userActions } from '../Reducer';
import { AppDispatch, RootState } from '../store';
// import scoreceleration from '../assets/scorecelebration.mp4';

function FinalScreen(props: any) {
  // @ts-ignore
  const score = useSelector((state: RootState) => state.score);
  const navigate = useNavigate();
  // defining to dispatch the actions
  const dispatch: AppDispatch = useDispatch();
  const questionCategory = useSelector((state: RootState) => state.selectedQuestionCategory);
  const questionIndex = useSelector((state: RootState) => state.index);
  const numberOfQuestions = useSelector((state: RootState) => state.options.amount_of_questions);
  const totalQuestionLength = useSelector((state: RootState) => state.totalQuestion);
  const personality = useSelector((state: RootState) => state.personalityScore);
  const [finalValue, setFinalValue] = React.useState("Final Score: " + score);
 
  useEffect(() => {
    dispatch(userActions.IS_QUIZINPROGRESS(false as never));
    if (questionCategory === 'Personality') {
      const highestPersonalityKey = Object.keys(personality).reduce((a, b) =>
        personality[a as keyof typeof personality] > personality[b as keyof typeof personality] ? a : b);
       setFinalValue("Your Personality is: " + highestPersonalityKey);
    }
  }, [questionCategory, personality, dispatch])
  
  const replay = () => {
    const reset: any = 0;
    dispatch(userActions.SET_INDEX((questionIndex - numberOfQuestions) as never));
    dispatch(userActions.SET_SCORE(reset as never));
    props.clickEvent(questionCategory, (questionIndex -numberOfQuestions));
  }

  const navigateToSettings = () => {
    dispatch(userActions.SET_QUESTIONS(null as never));
    dispatch(userActions.SET_SCORE(0 as never));
    dispatch({
      type: 'SET_QUESTIONS',
      questions: [],
    })

    dispatch({
      type: 'SET_SCORE',
      score: 0,
    })
    navigate("/Settings")
  }

  function fetchNextSetOfQuestions() {
    dispatch(userActions.SET_SCORE(0 as never));
    props.fetchNewQuestions(questionCategory, questionIndex);
  }

  const navigateToHome = () => {
    window.location.reload();
  }

  return (
    <div className='score-container'>
      {/* <div className='video-wrapper'>
          <video className="video-background" src={scoreceleration} autoPlay loop muted playsInline></video>
        </div> */}
      <h3 className='finalscore'>{finalValue}</h3>
      <div className='button-container'>
        <Button className='button' variant="contained" color="secondary" onClick={replay}>Try Again</Button>
        <Button className='button' variant="contained" color="secondary" onClick={fetchNextSetOfQuestions} disabled={totalQuestionLength === questionIndex}>Fetch New Questions</Button>
        <Button className='button' variant="contained" color="secondary" onClick={navigateToSettings}>Back to Settings</Button>
        <Button className='button' variant="contained" color="secondary" onClick={navigateToHome}>Go To Home</Button>
       </div>
    </div>
  )
}
export default FinalScreen
