import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { userActions } from '../../Reducer';

export default function CardComponent(props: any) {
  // defining to dispatch the actions
  const dispatch: AppDispatch = useDispatch();

  const cardList = [
    {
      id: 'Personality', path: require('../../../src/assets/Personality.mp4'), altText: "personality quiz image", title: 'Personality Quiz',
      text: 'Personality tests are important because they help individuals gain deeper self-awareness, enabling them to understand their strengths, weaknesses, and preferences. Enjoy the quiz!'
    },
    {
      id: 'States', path: require('../../../src/assets/states.png'), altText: "counties image", title: 'US States Quiz',
      text: 'Knowing the states of a country is important for understanding geographical, cultural, and political contexts, which can enhance travel, education, and social interactions!'
    },
    {
      id: 'Olympics', path: require('../../../src/assets/olympicscard.png'), altText: "Olympics quiz image", title: 'Olympics 2024 Quiz',
      text: 'Olympics quizzes test your knowledge about the history, athletes, and events of the Games, offering a fun way to engage with Olympic sports. Enjoy the games quiz!'
    },
    {
      id: 'USHistory', path: require('../../../src/assets/ushistory.png'), altText: "US History quiz image", title: 'US History Quiz',
      text: 'Understanding U.S. history is crucial as it helps us comprehend the nation\'s foundations, its cultural and political evolution, and the lessons learned from past events, which inform current and future decisions.!'
    },
    {
      id: 'GK', path: require('../../../src/assets/gkcard.png'), altText: "GK quiz image", title: 'GK Quiz',
      text: 'India is one of the strongest countries in the world. It is the seventh-largest country in the world.Enjoy the GK quiz about India.'
    },
    {
      id: 'Maths', path: require('../../../src/assets/mathscard.png'), altText: "Maths quiz image", title: 'Maths Quiz',
      text: 'The study of mathematics encourages critical thinking and enhances analytical abilities. We have curated the math quiz ranging from easy to difficult. Enjoy the quiz!'
    },
    {
      id: 'Science', path: require('../../../src/assets/sciencecard.png'), altText: "Science quiz image", title: 'Science Quiz',
      text: 'Scientific knowledge allows us to develop new technologies, solve practical problems, and make informed decisions — both individually and collectively. Enjoy the science quiz!'
    }
  ];

  const questionCategory = useSelector((state: RootState) => state.options.question_category);
  const filteredResults = questionCategory !== 'All' ? cardList.filter((list: any) => list.id === questionCategory) : cardList;

  const openQuiz = (open: string) => () => {
    dispatch(userActions.SET_INDEX(0 as never));
    dispatch(userActions.SET_SCORE(0 as never));
    props.clickEvent(open, 0);
  };
  return (
    <>
      <div className='card-container'>
      <Grid container mt={2}  spacing={2}>
        {filteredResults.map((card, i) => {
          return (

            <Card key={card.id}  className="card-component" sx={{ maxWidth: 345 }} onClick={openQuiz(card.id)}>
              <CardActionArea>
              {card.id === 'Personality' && <CardMedia
                  component="video"
                  image={card.path}
                  autoPlay
                  muted
                  loop
                /> }
                 {card.id !== 'Personality' &&
                <CardMedia
                  component="img"

                  image={card.path}
                  alt={card.altText}
                /> }
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {card.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {card.text}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>

          )
        })
        }
        </Grid>
      </div>
    </>
  );
}